<template>
  <div v-if="!!loadedData" class="">
    <Plotly :graphData="graphData" graphTitle="Orbits" />
  </div>
</template>

<script>
import { getPhotoGraphData, getPhotosColorsArray } from "../utils";
import Plotly from "./Plotly.vue";

export default {
  name: "OrbitGraph",
  props: {
    photos: Array,
  },
  components: {
    Plotly,
  },
  methods: {
    loadData() {
      if (!this.photos) return console.error("No photos array found!");
      const photosGraphData = getPhotoGraphData(this.photos);
      this.photos_longitude = photosGraphData.photos_longitude;
      this.photos_latitude = photosGraphData.photos_latitude;
      this.photos_absolute_altitude = photosGraphData.photos_absolute_altitude;
      this.colors = getPhotosColorsArray(this.photos);
    },
    getGraphData() {
      this.loadData();
      this.graphData = [
        {
          name: "",
          type: this.graphType,
          mode: this.graphMode,
          x: this.photos_longitude,
          y: this.photos_latitude,
          z: this.photos_absolute_altitude,
          opacity: 0.7,
          marker: {
            size: 2.5,
            color: this.colors,
          },
          hovertemplate:
            `<span>lng</span>: %{x:.6f}` +
            `<br><span>lat</span>: %{y:.6f}<br>` +
            `<span>abs alt</span>: %{z:.6f}<br>`,
        },
      ];
      this.loadedData = true;
    },
  },

  data() {
    return {
      photos_longitude: [],
      photos_latitude: [],
      photos_absolute_altitude: [],
      colors: [],
      graphType: "scatter3d",
      graphMode: "markers",
      loadedData: false,
    };
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    photos: function () {
      this.getGraphData();
    },
  },
};
</script>
