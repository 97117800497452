<template>
  <div class="bg-white border-b border-gray-200 sm:p-6">
    <a id="summary" class="font-bold text-lg justify-self-center"
      >Project Summary</a
    >
    <div class="flex w-full mt-2">
      <span class="w-72 font-medium">Number of photos:</span>
      <span class="text-gray-700 font-normal pl-8">{{ this.totalPhotos }}</span>
    </div>
    <div v-if="cameraType" class="flex w-full mt-2">
      <span class="w-72 font-medium">Camera type:</span>
      <span class="text-gray-700 font-normal pl-8">{{ this.cameraType }}</span>
    </div>
    <div v-if="droneType" class="flex w-full mt-2">
      <span class="w-72 font-medium">Drone type:</span>
      <span class="text-gray-700 font-normal pl-8">{{ this.droneType }}</span>
    </div>
    <div v-if="totalFlightTime" class="flex w-full mt-2">
      <span class="w-72 font-medium">Total flight time:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.totalFlightTime
      }}</span>
    </div>
    <div v-if="totalDistance" class="flex w-full mt-2">
      <span class="w-72 font-medium">Total flight distance:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.totalDistance
      }}</span>
    </div>
    <div v-if="droneAvgSpeed" class="flex w-full mt-2">
      <span class="w-72 font-medium">Drone average speed:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.droneAvgSpeed
      }}</span>
    </div>
    <div v-if="informedTowerHeight" class="flex w-full mt-2">
      <span class="w-72 font-medium">Informed Tower Height:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.informedTowerHeight
      }}</span>
    </div>
    <div v-if="estimatedTowerHeight" class="flex w-full mt-2">
      <span class="w-72 font-medium">Estimated Tower Height:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.estimatedTowerHeight
      }}</span>
    </div>
    <div v-if="highestPhotoRelativeAltitude" class="flex w-full mt-2">
      <span class="w-72 font-medium">Highest Drone Relative Altitude:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.highestPhotoRelativeAltitude
      }}</span>
    </div>
    <div v-if="lowestPhotoRelativeAltitude" class="flex w-full mt-2">
      <span class="w-72 font-medium">Lowest Drone Relative Altitude:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.lowestPhotoRelativeAltitude
      }}</span>
    </div>
    <div v-if="highestPhotoAltitude" class="flex w-full mt-2">
      <span class="w-72 font-medium">Highest Photo GPS Altitude (WSG84):</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.highestPhotoAltitude
      }}</span>
    </div>
    <div v-if="lowestPhotoAltitude" class="flex w-full mt-2">
      <span class="w-72 font-medium">Lowest Photo GPS Altitude (WSG84):</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.lowestPhotoAltitude
      }}</span>
    </div>
    <div v-if="flightTotalHeight" class="flex w-full mt-2">
      <span class="w-72 font-medium">Flight Total Height:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.flightTotalHeight
      }}</span>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 mt-10">
      <div class="grid">
        <span class="w-72 font-medium pb-2 text-lg">Flight path</span>
        <FlightGraph :photos="this.photos" />
      </div>
      <div class="grid">
        <span class="w-72 font-medium pb-2 text-lg">Orbits</span>
        <OrbitGraph :photos="this.photos" />
      </div>
    </div>
  </div>
</template>

<script>
import FlightGraph from "./FlightGraph.vue";
import OrbitGraph from "./OrbitGraph.vue";
import { m2ft, m2mile, ms2mph, parseSecsToFormatedTime } from "../utils";

export default {
  name: "ProjectSummary",
  props: ["reportData", "photos"],
  components: {
    FlightGraph,
    OrbitGraph,
  },
  data() {
    return {
      projectName: "",
      totalPhotos: 0,
      cameraType: "",
      droneType: "",
      totalFlightTime: "",
      totalDistance: "",
      guessedFromTowerHeight: "",
      droneAvgSpeed: "",
      towerHeight: "",
      heightsDifference: "",
      highestPhotoAltitude: "",
      minAltitude: "",
      informedTowerHeight: "",
      estimatedTowerHeight: "",
    };
  },
  methods: {
    mountSummaryData() {
      if (!this.reportData) return;
      const { general, tower_data } = this.reportData;
      if (!general) return;
      const {
        project,
        total_photos,
        camera_type,
        drone_type,
        total_flight_time_in_seconds,
        flight_speed_mean,
        guessed_from_tower_height,
        highest_photo_relative_altitude,
        lowest_photo_relative_altitude,
        highest_photo_altitude,
        lowest_photo_altitude,
        flight_total_height,
        total_distance,
      } = general;
      const { informed_tower_height, estimated_tower_height } = tower_data;
      this.projectName = project;
      this.totalPhotos = total_photos;
      this.cameraType = camera_type;
      this.droneType = drone_type;
      this.totalFlightTime = total_flight_time_in_seconds
        ? parseSecsToFormatedTime(total_flight_time_in_seconds)
        : null;
      this.totalDistance = total_distance
        ? `${total_distance.toFixed(2)} m (${m2mile(total_distance)} mi)`
        : null;
      this.droneAvgSpeed = flight_speed_mean
        ? `${flight_speed_mean.toFixed(2)} m/s (${ms2mph(
            flight_speed_mean
          )} mph)`
        : null;
      this.guessedFromTowerHeight = guessed_from_tower_height ? "Yes" : "No";
      this.informedTowerHeight =
        informed_tower_height && informed_tower_height > 0
          ? `${m2ft(informed_tower_height)} ft`
          : null;
      this.estimatedTowerHeight =
        estimated_tower_height && estimated_tower_height > 0
          ? `${m2ft(estimated_tower_height)} ft`
          : null;
      this.highestPhotoRelativeAltitude =
        highest_photo_relative_altitude && highest_photo_relative_altitude > 0
          ? `${m2ft(highest_photo_relative_altitude)} ft`
          : null;
      this.lowestPhotoRelativeAltitude =
        lowest_photo_relative_altitude && lowest_photo_relative_altitude > 0
          ? `${m2ft(lowest_photo_relative_altitude)} ft`
          : null;
      this.flightTotalHeight =
        flight_total_height && flight_total_height > 0
          ? `${m2ft(flight_total_height)} ft`
          : null;
      this.highestPhotoAltitude =
        highest_photo_altitude && highest_photo_altitude > 0
          ? `${highest_photo_altitude} m`
          : null;
      this.lowestPhotoAltitude =
        lowest_photo_altitude && lowest_photo_altitude > 0
          ? `${lowest_photo_altitude} m`
          : null;
    },
  },
  mounted() {
    this.mountSummaryData();
  },
  watch: {
    reportData: function () {
      this.mountSummaryData();
    },
  },
};
</script>
