import * as model from "./model";

export const getReport = async (projectId, debug) => {
  try {
    const result = await model.getReport(projectId, debug);
    if (!result) throw new Error();
    return result;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getQCAnalysis = async (projectId) => {
  try {
    const result = await model.getQCAnalysis(projectId);
    return result;
  } catch (e) {
    throw new Error(e.message);
  }
};
