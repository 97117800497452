export const METERS_IN_DEGREE = 111320;
export const HEX_COLORS = [
  "#9c6644",
  "#2a9d8f",
  "#a5a58d",
  "#f4a261",
  "#e76f51",
  "#cb997e",
  "#b7b7a4",
  "#e9c46a",
  "#48cae4",
  "#03045e",
  "#023e8a",
  "#0077b6",
  "#f72585",
  "#00b4d8",
  "#6b705c",
  "#0096c7",
  "#b5179e",
  "#7209b7",
  "#480ca8",
  "#e5989b",
  "#b5838d",
  "#e63946",
  "#be2edd",
  "#1d3557",
  "#b08968",
  "#e056fd",
  "#264653",
  "#7f5539",
  "#6ab04c",
  "#6a040f",
  "#9d0208",
  "#d00000",
  "#dc2f02",
  "#e85d04",
  "#f48c06",
  "#faa307",
  "#ffba08",
  "#c9184a",
  "#ff4d6d",
  "#ff758f",
  "#ff8fa3",
  "#008000",
  "#38b000",
  "#70e000",
  "#9ef01a",
  "#2f3542",
  "#ce4257",
  "#ff7f51",
  "#ff9b54",
  "#284b63",
  "#3c6e71",
  "#dbb42c",
  "#c9a227",
  "#b69121",
  "#a47e1b",
  "#926c15",
  "#805b10",
  "#da627d",
  "#a53860",
  "#450920",
  "#62b6cb",
  "#1b4965",
  "#5fa8d3",
  "#ff0000",
  "#810000",
  "#500000",
  "#ffd900",
  "#816e00",
  "#524500",
  "#15ff00",
  "#0b8500",
  "#064900",
  "#00ffff",
  "#008181",
  "#004949",
  "#0004ff",
  "#000281",
  "#000152",
  "#ff00ff",
  "#800080",
  "#460046",
  "#ff8800",
  "#b15e00",
  "#723d00",
  "#c5c5c5",
  "#818181",
  "#3d3d3d",
  "#000000",
  "#F4ABAA",
  "#E7DBBC",
  "#969381",
  "#736060",
  "#97703C",
  "#9DEBDD",
  "#A77500",
  "#00CDE2",
  "#003177",
  "#D369B4",
  "#F5E1FF",
  "#ECFBFF",
  "#D25B88",
  "#2D1B21",
  "#4F4A43",
  "#9F94F0",
  "#00B6C5",
  "#6A714A",
  "#006679",
  "#FFF46D",
  "#FF7B7D",
  "#CFCDAC",
  "#C64289",
  "#94DDC5",
  "#D60034",
  "#2C3533",
  "#8502AA",
  "#FF6F01",
  "#B8552F",
  "#7E6405",
  "#FF34FF",
  "#741D16",
  "#7FD4D9",
  "#363DFF",
  "#C7706A",
  "#FCC7DB",
  "#FF9E6B",
  "#BC908A",
  "#3B164A",
  "#4E1E2A",
  "#34eba4",
  "#ab5615",
  "#a0db65",
  "#780d3b",
  "#85948e",
  "#5aba00",
  "#4562e6",
  "#253063",
  "#ff4747",
  "#9c8f70",
  "#9b22c7",
  "#485c55",
  "#f7ebb2",
  "#ffb361",
  "#00b0ad",
  "#457dff",
  "#0092ba",
  "#f26a44",
  "#99a154",
  "#a35536",
  "#8265eb",
  "#3B163A",
  "#4E2E2A",
  "#00A6C5",
  "#6A714B",
  "#007579",
  "#705335",
  "#587246",
  "#FE0000",
  "#79553D",
  "#25221B",
  "#924E7D",
  "#EFA94A",
  "#E5BE01",
  "#D6AE01",
  "#8A9597",
  "#CF3476",
  "#AEA04B",
  "#826C34",
  "#641C34",
  "#909090",
  "#008F39",
  "#BDECB6",
  "#EA899A",
  "#2E3A23",
  "#49678D",
  "#ED760E",
  "#781F19",
  "#332F2C",
  "#CB3234",
  "#26252D",
  "#DC9D00",
  "#008F39",
  "#CF3476",
  "#256D7B",
  "#20214F",
  "#C6A664",
  "#474B4E",
  "#E7EBDA",
  "#1E5945",
  "#57A639",
  "#2A6478",
  "#424632",
  "#606E8C",
  "#2D572C",
  "#6C6960",
  "#7D7F7D",
  "#686C5E",
  "#755C48",
  "#A52019",
  "#2271B3",
  "#9D9101",
  "#D6AE01",
  "#F3A505",
  "#3D642D",
  "#909090",
  "#18171C",
  "#7D8471",
  "#641C34",
  "#FFA420",
  "#FDF4E3",
  "#A5A5A5",
  "#FF7514",
  "#1E1E1E",
  "#015D52",
  "#5B3A29",
  "#8673A1",
  "#FE0000",
  "#474B4E",
  "#C1876B",
  "#F80000",
  "#955F20",
  "#008F39",
  "#84C3BE",
  "#1F3A3D",
  "#705335",
  "#CB3234",
  "#4D5645",
  "#BDECB6",
  "#A12312",
  "#FE0000",
  "#E63244",
  "#9B111E",
  "#4A192C",
  "#C93C20",
  "#18171C",
  "#F39F18",
  "#2E3A23",
  "#BDECB6",
  "#25221B",
  "#78858B",
  "#75151E",
  "#382C1E",
  "#CDA434",
  "#9DA1AA",
  "#CAC4B0",
  "#4C514A",
  "#6C4675",
  "#102C54",
  "#4A192C",
  "#922B3E",
  "#0A0A0A",
  "#F5D033",
];
