<template>
  <div
    v-if="loaded"
    class="bg-white sm:p-6 overflow-x-auto overflow-y-hidden flex"
  >
    <img
      v-for="(photo, i) in photos"
      :key="i"
      :src="photo.path"
      class="m-2"
      style="max-width: 75%"
    />
  </div>
</template>

<script>
export default {
  name: "PhotosViewer",
  props: ["photosSrc", "photosBaseUrl"],

  data() {
    return {
      photos: [],
      loaded: false,
    };
  },
  methods: {
    mountPhotos() {
      if (!this.photosSrc || !this.photosBaseUrl) return;
      this.photos = this.photosSrc.map((src) => ({
        path: `${this.photosBaseUrl}${src}`,
      }));
      this.loaded = true;
    },
  },
  mounted() {
    this.mountPhotos();
  },
  watch: {
    photosSrc: function () {
      this.mountPhotos();
    },
  },
};
</script>