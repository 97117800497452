<template>
  <div v-if="!loadedData" id="preloader" class="overlay">
    <div class="loader border-r-primay-500"></div>
  </div>
  <div v-if="!!loadedData" class="font-sans w-full grid">
    <div class="grid p-6">
      <span class="mb-10 text-lg leading-6 font-medium text-gray-900"
        >Flight Analysis Report</span
      >
      <TableContent :qcMode="this.qcMode" :reportData="this.reports" />
      <div v-if="qcMode" class="pt-4">
        <QCAnalysis :qcAnalysisData="this.qcAnalysis" />
      </div>
      <div class="pt-4">
        <Summary :reportData="this.reports" :photos="this.photos" />
      </div>
      <!-- <div class="pt-4">
        <ProcessingData :processingData="this.processingData" />
      </div> -->
      <div class="pt-4">
        <PhotosQuantity :processingData="this.processingData" />
      </div>
      <div class="pt-4">
        <PhotosPerSectorAndOrbit :processingData="this.processingData" />
      </div>
      <div class="pt-4">
        <AvgSpeed :processingData="this.processingData" />
      </div>
      <div class="pt-4">
        <TowerData
          :reportData="this.reports"
          :photos="this.photos"
          :imagesBaseUrl="this.imagesBaseUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as repository from "../repository";
import TableContent from "./TableContent.vue";
import Summary from "./Summary.vue";
// import ProcessingData from "./ProcessingData.vue";
import PhotosQuantity from "./PhotosQuantity.vue";
import PhotosPerSectorAndOrbit from "./PhotosPerSectorAndOrbit.vue";
import AvgSpeed from "./AvgSpeed.vue";
import QCAnalysis from "./QCAnalysis.vue";
import TowerData from "./TowerData.vue";

const UNEXPECTED_ERROR =
  "An unexpected error has occurred, please try again later.";

export default {
  name: "ReportData",
  components: {
    TableContent,
    Summary,
    // ProcessingData,
    PhotosQuantity,
    PhotosPerSectorAndOrbit,
    AvgSpeed,
    QCAnalysis,
    TowerData,
  },
  methods: {
    getProjectTitle(photos) {
      if (!photos) return console.error("No photos array found!");
      return photos[0].file.split("_")[0];
    },
    parseReportData(reportData) {
      const { reports, photos, qc_analysis, images_base_url } = reportData;
      this.imagesBaseUrl = images_base_url;
      this.photos = photos;
      this.reports = reports;

      this.processingData = {
        totalPhotos: this.reports.general.total_photos,
        totalInvalidPhotos: this.reports.general.total_invalid_photos,
        totalValidPhotos: this.reports.general.total_valid_photos,
        reports: this.reports,
      };
      if (qc_analysis) {
        this.qcAnalysis = { ...qc_analysis, photos: this.photos };
      }
    },
    async getReportData(projectId, debug) {
      try {
        const result = await repository.getReport(projectId, debug);
        console.log({result})
        this.parseReportData(result);
        this.loadedData = true;
      } catch (e) {
        alert(UNEXPECTED_ERROR);
        return;
      }
    },
    async getQCData(projectId) {
      try {
        const result = await repository.getQCAnalysis(projectId);
        this.parseReportData(result);
        this.qcMode = true;
        this.loadedData = true;
      } catch (e) {
        alert(UNEXPECTED_ERROR);
        return;
      }
    },
  },

  data() {
    return {
      input: Array,
      title: String,
      reports: {},
      qcAnalysis: {},
      processingData: {},
      imagesBaseUrl: "",
      qcMode: false,
      loadedData: false,
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const qcMode = params.get("qc_app");
    const projectId = params.get("id");
    const debug = params.get("_debug");
    if (!projectId) {
      alert("No Project ID found");
      return;
    }
    if (qcMode && qcMode === "true") {
      this.getQCData(projectId);
    } else {
      this.getReportData(projectId, debug);
    }
  },
};
</script>

<style scoped>
.title-shadow {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.overlay {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid rgba(36, 56, 82, 0.25);
  border-right-color: #243852;
  transform-origin: center;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
