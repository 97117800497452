<template>
  <div v-if="loaded" class="bg-white sm:p-6">
    <a id="average-speed" class="font-bold text-lg justify-self-center"
      >Average Speed</a
    >
    <div class="flex w-full mt-2">
      <span class="w-60 font-medium">Drone average speed:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.droneAvgSpeed
      }}</span>
    </div>
    <div class="flex flex-col mt-10">
      <div class="flex flex-col">
        <span class="w-60 font-medium pb-2 text-lg"
          >Average Speed per orbit</span
        >
        <AvgSpeedPerClusterGraph :clusters="this.clusters" />
      </div>
    </div>
  </div>
</template>

<script>
import AvgSpeedPerClusterGraph from "./AvgSpeedPerClusterGraph.vue";
import { ms2mph } from "../utils";

export default {
  name: "AvgSpeed",
  props: ["processingData"],
  components: {
    AvgSpeedPerClusterGraph,
  },
  data() {
    return {
      clusters: [],
      droneAvgSpeed: "",
      loaded: false,
    };
  },
  methods: {
    getClusters(data_per_cluster) {
      return Object.values(data_per_cluster);
    },
    getSectors(data_per_sector) {
      return Object.values(data_per_sector).map((data) => ({
        ...data,
        per_cluster: Object.values(data.per_cluster),
      }));
    },
    mountProcessingData() {
      if (!this.processingData) return;

      const { reports } = this.processingData;
      const { general, data_per_cluster } = reports;
      const { flight_speed_mean } = general;
      this.clusters = this.getClusters(data_per_cluster);
      this.droneAvgSpeed = this.droneAvgSpeed = flight_speed_mean
        ? `${flight_speed_mean.toFixed(2)} m/s (${ms2mph(
            flight_speed_mean
          )} mph)`
        : null;
      this.loaded = !!this.droneAvgSpeed;
    },
  },
  mounted() {
    this.mountProcessingData();
  },
  watch: {
    processingData: function () {
      this.mountProcessingData();
    },
  },
};
</script>