import { sleep } from "@/utils";
import * as api from "./api";

export const getReport = async (projectId, debug) => {
  const response = await api.getReport(projectId, debug);

  const { result, images_base_url } = response;
  return { ...result, images_base_url };
};

export const getQCAnalysis = async (projectId) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    // eslint-disable-next-line no-constant-condition
    const poolingTimer = setInterval(async () => {
      try {
        const response = await api.getQCAnalysis(projectId);
        const { flight_qc_pre_analysis } = response;

        if (
          flight_qc_pre_analysis.status === "FINISHED" &&
          flight_qc_pre_analysis.result
        ) {
          clearInterval(poolingTimer);
          resolve(flight_qc_pre_analysis.result);
        }
        await sleep(1000);
      } catch (e) {
        clearInterval(poolingTimer);
        reject(e);
      }
    }, 2500);
  });
};
