<template>
  <div v-if="!!loadedData">
    <Plotly :graphData="graphData" graphTitle="Average Speed Per Orbit" />
  </div>
</template>

<script>
import Plotly from "./Plotly.vue";

import { HEX_COLORS } from "../constants";
export default {
  name: "AvgSpeedPerClusterGraph",
  props: {
    clusters: Array,
  },
  components: {
    Plotly,
  },
  methods: {
    loadData() {
      if (!this.clusters) return console.error("No clusters array found!");
    },
    getGraphData() {
      this.loadData();
      this.graphData = this.clusters.map(({ means_speed }, index) => ({
        type: this.graphType,
        name: `Orbit ${index + 1}`,
        x: [`c${index + 1}`],
        y: [means_speed],
        marker: {
          color: HEX_COLORS[index],
          line: {
            width: 1.5,
          },
        },
      }));

      this.loadedData = true;
    },
  },

  data() {
    return {
      graphType: "bar",
      loadedData: false,
    };
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    clusters: function () {
      this.getGraphData();
    },
  },
};
</script>

