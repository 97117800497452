<template>
  <div class="bg-white border-b border-gray-200 sm:p-6">
    <a id="quality-control" class="font-bold text-lg justify-self-center"
      >Quality Control</a
    >
    <div class="flex w-full mt-4">
      <span class="w-72 font-medium">Overall Quality Rating:</span>
      <span class="text-gray-700 font-normal pl-8"
        >{{ this.totalRating }}
      </span>
      <div :class="[`self-center rounded-full ml-2 p-3 ${qcColor}`]"></div>
    </div>
    <div v-if="altitudeDifferenceBetweenOrbitsRating" class="flex w-full mt-2">
      <span class="w-72 font-medium"
        >Rating of Altitude Difference Between Orbits:</span
      >
      <span class="text-gray-700 font-normal pl-8">{{
        this.altitudeDifferenceBetweenOrbitsRating
      }}</span>
    </div>
    <div v-if="angleDifferenceBetweenPhotosRating" class="flex w-full mt-2">
      <span class="w-72 font-medium"
        >Rating of Angle Difference Between Photos:</span
      >
      <span class="text-gray-700 font-normal pl-8">{{
        this.angleDifferenceBetweenPhotosRating
      }}</span>
    </div>
    <div v-if="errors.length" class="w-ull mt-2">
      <span class="w-72 font-medium">Found {{ errors.length }} issues:</span>
      <div class="mt-6">
        <div v-for="(error, i) in errors" :key="i" class="my-2">
          <ErrorAccordion :data="error" :photos="photos" :index="i" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorAccordion from "./ErrorAccordion.vue";
import { formatQCError } from "../utils";

export default {
  name: "QCAnalysis",
  props: ["qcAnalysisData"],
  components: {
    ErrorAccordion,
  },
  data() {
    return {
      totalRating: "",
      altitudeDifferenceBetweenOrbitsRating: null,
      angleDifferenceBetweenPhotosRating: null,
      errors: [],
      photos: [],
      qcColor: "",
    };
  },
  methods: {
    mountQCAnalysisData() {
      if (!this.qcAnalysisData) return;
      const { rating, errors, photos } = this.qcAnalysisData;
      const {
        total_rating,
        altitude_difference_between_orbits_rating,
        angle_difference_between_photos_rating,
      } = rating;
      this.errors = errors.map(formatQCError);
      this.totalRating = parseFloat(total_rating * 10).toFixed() + "%";
      this.altitudeDifferenceBetweenOrbitsRating =
        altitude_difference_between_orbits_rating;
      this.angleDifferenceBetweenPhotosRating =
        angle_difference_between_photos_rating;
      this.photos = photos;
      if (total_rating >= 8.5) {
        this.qcColor = "bg-green-500";
      } else if (total_rating < 6) {
        this.qcColor = "bg-red-500";
      } else {
        this.qcColor = "bg-yellow-500";
      }
    },
  },
  mounted() {
    this.mountQCAnalysisData();
  },
  watch: {
    qcAnalysisData: function () {
      this.mountQCAnalysisData();
    },
  },
};
</script>