<template>
  <Report />
</template>

<script>
import Report from "./components/Report.vue";

export default {
  name: "App",
  components: {
    Report,
  },
};
</script>
