<template>
  <div v-if="loaded" class="bg-white border-b border-gray-200 sm:p-6">
    <a
      id="photos-per-sector-and-orbit"
      class="font-bold text-lg justify-self-center"
      >Photos per Sector and Orbit</a
    >
    <div class="flex w-full mt-2">
      <span class="font-medium"
        ><i
          >Top view of the flight is split into 12 circular sectors of 30
          degrees.</i
        ></span
      >
    </div>
    <div class="mt-10">
      <span class="font-medium pb-2 text-lg">Data per sectors</span>
      <div class="grid w-full">
        <div class="flex max-w-5xl w-full overflow-x-auto flex-col pb-8 justify-self-center">
          <DataPerSectorGraph
            :sectors="this.sectors"
            :clusters="this.clusters"
          />
        </div>
        <div
          class="
            flex
            max-w-7xl
            w-full
            justify-self-center
            overflow-x-auto
            flex-col
          "
        >
          <DataPerSectorGraphTable
            :sectors="this.sectors"
            :clusters="this.clusters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataPerSectorGraph from "./DataPerSectorGraph.vue";
import DataPerSectorGraphTable from "./DataPerSectorGraphTable.vue";

export default {
  name: "PhotosPerSectorAndOrbit",
  props: ["processingData"],
  components: {
    DataPerSectorGraph,
    DataPerSectorGraphTable,
  },
  data() {
    return {
      clusters: [],
      sectors: [],
      totalPhotos: "",
      loaded: false,
    };
  },
  methods: {
    getClusters(data_per_cluster) {
      return Object.values(data_per_cluster);
    },
    getSectors(data_per_sector) {
      return Object.values(data_per_sector).map((data) => ({
        ...data,
        per_cluster: Object.values(data.per_cluster),
      }));
    },
    mountProcessingData() {
      if (!this.processingData) return;
      const { totalPhotos, reports } = this.processingData;
      this.totalPhotos = totalPhotos;

      const { data_per_cluster, data_per_sector } = reports;
      this.clusters = this.getClusters(data_per_cluster);
      this.sectors = this.getSectors(data_per_sector);
      this.loaded = true;
    },
  },
  mounted() {
    this.mountProcessingData();
  },
  watch: {
    processingData: function () {
      this.mountProcessingData();
    },
  },
};
</script>