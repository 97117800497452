<template>
  <div v-if="loaded" class="bg-white sm:p-6">
    <a id="estimated-tower-height" class="font-bold text-lg justify-self-center"
      >Estimated tower height</a
    >
    <div class="flex w-full mt-4">
      <span class="w-72 font-medium">Informed Tower Height:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.informedTowerHeight
      }}</span>
    </div>
    <div class="flex w-full mt-2">
      <span class="w-72 font-medium">Estimated Tower Height:</span>
      <span class="text-gray-700 font-normal pl-8">{{
        this.estimatedTowerHeight
      }}</span>
    </div>
    <div class="w-full mt-4">
      <span class="font-bold text-md justify-self-center"
        >Photos used for height estimation</span
      >
      <div class="flex w-full mt-2">
        <span class="font-medium"
          ><i
            >The following group of photos was heuristically identified as the
            orbit more closely pointing to the top of the tower, the height
            estimation was based on the GPS altitude data embed on those photos
            (the better the top of the tower coincides with the center of the
            photos, the better the height estimation -- within the precision of
            the GPS altimeter).</i
          ></span
        >
      </div>
    </div>
    <div class="grid grid-cols-2 mt-10">
      <div>
        <TowerHeightGroupChart
          :photos="this.photos"
          :highlightPhotos="this.heightGroupPhotosIndexes"
        />
      </div>
      <div class="self-center">
        <PhotosViewer
          :photosBaseUrl="this.imagesBaseUrl"
          :photosSrc="this.heightGroupPhotosSrc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TowerHeightGroupChart from "./TowerHeightGroupChart.vue";
import PhotosViewer from "./PhotosViewer.vue";
import { m2ft } from "@/utils";

export default {
  name: "TowerData",
  props: ["reportData", "imagesBaseUrl", "photos"],
  components: {
    TowerHeightGroupChart,
    PhotosViewer,
  },
  data() {
    return {
      heightGroupPhotosIndexes: [],
      heightGroupPhotosSrc: [],
      informedTowerHeight: "",
      estimatedTowerHeight: "",
      loaded: false,
    };
  },
  methods: {
    mountReportData() {
      if (!this.reportData) return;

      const { tower_data } = this.reportData;
      if (!tower_data || !this.photos) return;
      const { informed_tower_height, estimated_tower_height } = tower_data;
      if (!informed_tower_height || !estimated_tower_height) return;
      if (informed_tower_height === 0 || !estimated_tower_height === 0) return;
      this.informedTowerHeight = `${m2ft(informed_tower_height)} ft`;
      this.estimatedTowerHeight = `${m2ft(estimated_tower_height)} ft`;
      this.heightGroupPhotosIndexes =
        tower_data.photos_index_of_estimated_tower_height;
      this.heightGroupPhotosSrc = this.photos
        .filter((photo) => photo.in_height_calc_group)
        .map(({ file }) => file);

      this.loaded = true;
    },
  },
  mounted() {
    this.mountReportData();
  },
  watch: {
    reportData: function () {
      this.mountReportData();
    },
  },
};
</script>
