import endpoints from "./endpoints";

const API_TOKEN = process.env.VUE_APP_API_TOKEN;

const getReport = async (projectId, debug) => {
  const headers = new Headers({
    "Content-Type": "Application/json",
    Authorization: `Bearer ${API_TOKEN}`,
  });

  const options = {
    method: "GET",
    cache: "default",
    headers,
  };
  const url = endpoints.getReport(projectId, debug);
  const result = await fetch(url, options);
  return result.json();
};

const getQCAnalysis = async (projectId) => {
  const headers = new Headers({
    "Content-Type": "Application/json",
    Authorization: `Bearer ${API_TOKEN}`,
  });

  const options = {
    method: "GET",
    cache: "default", 
    headers,
  };
  const url = endpoints.getQCAnalysis(projectId);
  const result = await fetch(url, options);
  return result.json();
};

export { getReport, getQCAnalysis };
