<template>
  <div v-if="!!loadedData">
    <Plotly
      :graphData="graphData"
      graphTitle="Data Per Sector Table"
      :additionalLayout="additionalLayout"
      :additionalConfig="additionalConfig"
    />
  </div>
</template>

<script>
import Plotly from "./Plotly.vue";

export default {
  name: "DataPerSectorGraphTable",
  props: {
    sectors: Array,
    clusters: Array,
  },
  components: {
    Plotly,
  },
  methods: {
    loadData() {
      if (!this.sectors) return console.error("No sectors array found!");
    },
    getHeaderValues() {
      const values = ["<b>Sectors</b>"];
      this.sectors.forEach((_, sector_index) =>
        values.push(`<b>Sector ${sector_index + 1}</b>`)
      );
      values.push(`<b>Total</b>`);
      return values;
    },
    getTableValues() {
      const values = [];
      values.push([
        ...this.clusters.map(
          (_, cluster_index) => `Orbit ${cluster_index + 1}`
        ),
        `<b>Photos</b>`,
      ]);

      this.sectors.forEach(({ per_cluster, photos_quantity }) => {
        const _cluster = this.clusters.slice().fill(0);

        per_cluster.forEach((photos, cluster_index) => {
          _cluster[cluster_index] = photos;
        });
        values.push([..._cluster, photos_quantity]);
      });
      let totalPerCluster = this.clusters.map(
        ({ photos_quantity }) => photos_quantity
      );
      totalPerCluster = [
        ...totalPerCluster,
        this.clusters
          .map(({ photos_quantity }) => photos_quantity)
          .reduce((acc, v) => v + acc),
      ];
      values.push(totalPerCluster);
      return values;
    },
    getGraphData() {
      this.loadData();
      this.graphData = [
        {
          type: "table",
          header: {
            values: this.getHeaderValues(),
            align: "center",
            line: { width: 1, color: "black" },
            fill: { color: "grey" },
            font: { family: "Arial", size: 12, color: "white" },
          },
          cells: {
            values: this.getTableValues(),
            align: "center",
            line: { color: "black", width: 1 },
            font: { family: "Arial", size: 11, color: ["black"] },
          },
        },
      ];
      const height = (this.clusters.length + 2) * 21 + 4;
      const width = (this.sectors.length + 1) * 90;

      this.additionalLayout = {
        autosize: false,
        height,
        width,
      };
      this.loadedData = true;
    },
  },

  data() {
    return {
      graphType: "bar",
      additionalLayout: {},
      additionalConfig: {},
      loadedData: false,
    };
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    sectors: function () {
      this.getGraphData();
    },
  },
};
</script>

