import { HEX_COLORS, METERS_IN_DEGREE } from "./constants";

export function hexToRgb(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ")";
  }
  throw new Error("Bad Hex");
}
export const getPhotosLongitudesArray = (photos) => {
  const photos_longitude = [];
  photos.forEach((photo) => {
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
  });
  return photos_longitude;
};
export const getPhotosLatitudesArray = (photos) => {
  const photos_latitude = [];
  photos.forEach((photo) => {
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
  });
  return photos_latitude;
};

export const getPhotosAbsAltitudesArray = (photos) => {
  const photos_absolute_altitude = [];
  photos.forEach((photo) => {
    photos_absolute_altitude.push(photo["absolute_altitude"]);
  });
  return photos_absolute_altitude;
};
export const getPhotosColorsArray = (photos) => {
  const photos_color = [];
  photos.forEach((photo) => {
    photos_color.push(HEX_COLORS[photo.cluster]);
  });
  return photos_color;
};

export const getPhotoGraphData = (photos) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const colors_indexes = [];
  photos.forEach((photo, index) => {
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);
    photos_color.push(HEX_COLORS[photo.cluster]);
    colors_indexes.push(index);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors_indexes,
  };
};

export const getErrorPhotoChartData = (photos) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const defaultColor = "#2686d4";
  const colors = [];
  photos.forEach((photo) => {
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);

    colors.push(defaultColor);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors,
  };
};
export const getNotInHeightGroupPhotoChartData = (photos) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const defaultColor = "#000";
  const colors = [];
  photos.forEach((photo) => {
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);

    colors.push(defaultColor);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors,
  };
};
export const getInHeightGroupPhotosChartData = (
  photos,
  heightGroupPhotos = []
) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const colors = [];
  const heightGroupPhoto = "#2686d4";
  photos.forEach((photo) => {
    const inHeightGroupPhoto = heightGroupPhotos.find(
      (index) => index === photo.index
    );
    if (!inHeightGroupPhoto) return;
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);
    colors.push(heightGroupPhoto);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors,
  };
};

export const getAffectedPhotosChartData = (photos, affectedPhotos = []) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const colors = [];
  const errorColor = "#d43226";
  photos.forEach((photo) => {
    const affectedPhoto = affectedPhotos.find((index) => index === photo.index);
    if (!affectedPhoto) return;
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);
    colors.push(errorColor);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors,
  };
};

export const getAffectedOrbitsChartData = (photos, affectedOrbits = []) => {
  const photos_longitude = [];
  const photos_latitude = [];
  const photos_absolute_altitude = [];
  const photos_color = [];
  const colors = [];
  const affectedOrbitColor = "#d49726";
  photos.forEach((photo) => {
    const affectedOrbit = affectedOrbits.find(
      (index) => index === photo.cluster
    );
    if (!affectedOrbit) return;
    photos_longitude.push(photo["longitude"] / METERS_IN_DEGREE);
    photos_latitude.push(photo["latitude"] / METERS_IN_DEGREE);
    photos_absolute_altitude.push(photo["absolute_altitude"]);
    colors.push(affectedOrbitColor);
  });
  return {
    photos_longitude,
    photos_latitude,
    photos_absolute_altitude,
    photos_color,
    colors,
  };
};

export const getPhotosPerClusters = (photos) => {
  let clusters = [];
  photos.forEach((photo) => {
    const clusterIndex = photo.cluster;
    if (clusters[clusterIndex]) {
      const photos = clusters[clusterIndex] + 1;
      clusters[clusterIndex] = photos;
    } else {
      clusters.push(1);
    }
  });
  return clusters;
};

export const percentile = (arr, val) =>
  (100 *
    arr.reduce(
      (acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0),
      0
    )) /
  arr.length;

export const getRelativeCenter = (photos) => {
  let logitudes = [];
  let latitudes = [];
  photos.forEach((photo) => {
    logitudes.push(photo.longitude);
    latitudes.push(photo.latitude);
  });
  const longitudes_median =
    logitudes.reduce((acc, v) => acc + v) / logitudes.length;
  const latitudes_median =
    latitudes.reduce((acc, v) => acc + v) / latitudes.length;
  return {
    longitude: longitudes_median,
    latitude: latitudes_median,
  };
};
const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

const photoIsInSector = (
  position,
  sectorDegreeMin,
  sectorDegreeMax,
  center
) => {
  const sectorRadiansMax = degreesToRadians(sectorDegreeMax);
  const sectorRadiansMin = degreesToRadians(sectorDegreeMin);

  let positionX = position.x - center.longitude;
  let positionY = position.y - center.latitude;

  const is_adjusted = positionY < 0;
  if (is_adjusted) positionY *= -1;

  let angle = Math.acos(positionX / Math.sqrt(positionX ** 2 + positionY ** 2));
  if (is_adjusted) angle = 2 * Math.PI - angle;

  return sectorRadiansMin <= angle && angle < sectorRadiansMax;
};

export const getPhotosPerSector = (photos, sectors_quantity = 12) => {
  const center = getRelativeCenter(photos);

  const photosPerSector = [];

  const sectorSizeDegree = 360 / sectors_quantity;
  for (let sectorNumber = 0; sectorNumber < 12; sectorNumber++) {
    const sectorDegreeMin = sectorNumber * sectorSizeDegree;
    const sectorDegreeMax = sectorDegreeMin + sectorSizeDegree;

    const photosInSector = photos.filter((photo) => {
      const position = { x: photo.longitude, y: photo.latitude };

      if (photoIsInSector(position, sectorDegreeMin, sectorDegreeMax, center)) {
        return photo;
      }
    });

    photosPerSector[sectorNumber] = photosInSector.length;
  }
  return photosPerSector;
};

export const getPhotosAvgPerCluster = (photos) => {
  let _clusters = [];
  photos.forEach((photo) => {
    const clusterIndex = photo.cluster;
    if (_clusters[clusterIndex]) {
      const _photos = _clusters[clusterIndex] + 1;
      _clusters[clusterIndex] = _photos;
    } else {
      _clusters.push(1);
    }
  });
  return (_clusters.reduce((a, b) => a + b, 0) / _clusters.length).toFixed(2);
};

export const m2ft = (meters) => (meters * 3.28084).toFixed(2);
export const m2mile = (meters) => (meters * 0.000621371).toFixed(3);
export const ms2mph = (ms) => (ms * 2.237).toFixed(3);

const errorsReasons = {
  altitude_difference_between_orbits: (altitude, threshold) =>
    `The altitude difference between the two consecutive orbits should not be larger than ${parseFloat(
      threshold
    ).toFixed(2)} meters. The altitude difference is of ${parseFloat(
      altitude
    ).toFixed(2)} meters.`,

  angular_difference_between_photos: (angle, threshold) =>
    `The angle between the two consecutive photos should not be larger than ${parseFloat(
      threshold
    ).toFixed(
      2
    )} degrees. The angle difference between the photos is of ${parseFloat(
      angle
    ).toFixed(2)} degrees.`,
};

const errorsTitle = {
  altitude_difference_between_orbits: "Altitude difference between orbits",

  angular_difference_between_photos: "Angular difference between photos",
};

export const formatQCError = (error) => {
  const description = errorsReasons[error.error_type](
    error.angle_between_photos || error.distance_between_orbits,
    error.maximum_angle_between_photos ||
      error.threshold_distance_between_orbits
  );
  const title = errorsTitle[error.error_type];

  return {
    title,
    description,
    highlightOrbit: error.affected_orbits,
    highlightPhotos:
      error.affected_photos_between_orbits || error.affected_photos,
  };
};

export const sleep = async (time) => {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const parseSecsToFormatedTime = (secs) => {
  const h = Math.floor((secs % (3600 * 24)) / 3600);
  const m = Math.floor((secs % 3600) / 60);
  const s = Math.floor(secs % 60);
  return h + ':' + m + ':' + s + " (h:mm:ss)";
}
