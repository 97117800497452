<template>
  <div v-if="loaded" class="bg-white border-b border-gray-200 sm:p-6">
    <a id="photos-distribution" class="font-bold text-lg justify-self-center"
      >Photos Distribution</a
    >
    <div class="flex w-full mt-2">
      <span class="w-60 font-medium">Number of photos:</span>
      <span class="text-gray-700 font-normal">{{ this.totalPhotos }}</span>
    </div>
    <div class="flex w-full mt-2">
      <span class="w-60 font-medium">Number of valid photos:</span>
      <span class="text-gray-700 font-normal">{{ this.totalValidPhotos }}</span>
    </div>
    <div class="flex w-full mt-2">
      <span class="w-60 font-medium">Number of invalid photos:</span>
      <span class="text-gray-700 font-normal">{{
        this.totalInvalidPhotos
      }}</span>
    </div>
    <div class="flex w-full mt-2">
      <span class="w-60 font-medium">Number of orbits:</span>
      <span class="text-gray-700 font-normal">{{ this.clusters.length }}</span>
    </div>
    <div class="flex flex-col mt-10">
      <div class="flex flex-col">
        <span class="w-60 font-medium pb-2 text-lg"
          >Number of photos per orbit:</span
        >
        <PhotosPerClusterGraph :clusters="this.clusters" />
      </div>
    </div>
  </div>
</template>

<script>
import PhotosPerClusterGraph from "./PhotosPerClusterGraph.vue";

export default {
  name: "PhotosQuantity",
  props: ["processingData"],
  components: {
    PhotosPerClusterGraph,
  },
  data() {
    return {
      clusters: [],
      sectors: [],
      totalPhotos: "",
      totalInvalidPhotos: "",
      totalValidPhotos: "",
      loaded: false,
    };
  },
  methods: {
    getClusters(data_per_cluster) {
      return Object.values(data_per_cluster);
    },
    getSectors(data_per_sector) {
      return Object.values(data_per_sector).map((data) => ({
        ...data,
        per_cluster: Object.values(data.per_cluster),
      }));
    },
    mountProcessingData() {
      if (!this.processingData) return;
      const { reports, totalPhotos, totalInvalidPhotos, totalValidPhotos } =
        this.processingData;
      this.totalPhotos = totalPhotos;
      this.totalInvalidPhotos = totalInvalidPhotos;
      this.totalValidPhotos = totalValidPhotos;

      const { data_per_cluster, data_per_sector } = reports;
      this.clusters = this.getClusters(data_per_cluster);
      this.sectors = this.getSectors(data_per_sector);
      this.loaded = true;
    },
  },
  mounted() {
    this.mountProcessingData();
  },
  watch: {
    processingData: function () {
      this.mountProcessingData();
    },
  },
};
</script>