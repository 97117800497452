<template>
  <div v-if="!!loadedData">
    <div>
      <Plotly
        :graphData="graphData"
        graphTitle="Data Per Sector"
        :additionalLayout="additionalLayout"
      />
    </div>
  </div>
</template>

<script>
import Plotly from "./Plotly.vue";

export default {
  name: "DataPerSectorGraph",
  props: {
    sectors: Array,
    clusters: Array,
  },
  components: {
    Plotly,
  },
  methods: {
    loadData() {
      if (!this.sectors) return console.error("No sectors array found!");
    },
    getLabels() {
      const labels = [];
      this.sectors.forEach((sector, index) => {
        labels.push(`Sector ${index + 1}`);
        sector.per_cluster.forEach((_, cluster_index) => {
          labels.push(`Sector ${index + 1} - Orbit ${cluster_index + 1}`);
        });
      });
      return labels;
    },
    getParents() {
      const parents = [];
      this.sectors.forEach((sector, index) => {
        parents.push("");
        sector.per_cluster.forEach(() => {
          parents.push(`Sector ${index + 1}`);
        });
      });
      return parents;
    },
    getValues() {
      const values = [];
      this.sectors.forEach((sector) => {
        values.push(sector.photos_quantity);
        sector.per_cluster.forEach((photos) => {
          values.push(photos);
        });
      });
      return values;
    },
    getGraphData() {
      this.loadData();
      this.graphData = [
        {
          type: "sunburst",
          labels: this.getLabels(),
          parents: this.getParents(),
          values: this.getValues(),
          outsidetextfont: { size: 24, color: "#377eb8" },
          leaf: { opacity: 0.4 },
          marker: { line: { width: 2 } },
        },
      ];
      this.loadedData = true;
    },
  },

  data() {
    return {
      graphType: "bar",
      loadedData: false,
    };
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    sectors: function () {
      this.getGraphData();
    },
  },
};
</script>

