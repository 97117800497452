<template>
  <div v-bind:id="containerId" class="plotly"></div>
</template>

<script>
import Plotly from "plotly.js-dist-min";

export default {
  name: "PlotlyChart",
  props: {
    graphTitle: String,
    graphData: Array,
    additionalLayout: Object,
    additionalConfig: Object,
  },
  data() {
    return {
      containerId: `plotly-${this.graphTitle}`,
    };
  },
  mounted() {
    const additionalLayout = this.additionalLayout || {};
    const additionalConfig = this.additionalConfig || {};
    const data = this.graphData;
    const layout = {
      font: { size: 10 },
      margin: {
        l: 2,
        r: 2,
        b: 2,
        t: 2,
        pad: 2,
      },
      ...additionalLayout,
    };
    const config = {
      responsive: true,
      ...additionalConfig,
    };
    Plotly.newPlot(this.containerId, data, layout, config);
  },
};
</script>
<style scoped>
.plotly {
  box-sizing: initial;
}
</style>