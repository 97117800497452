<template>
  <div class="bg-white border-b border-gray-200 sm:p-6">
    <div class="grid">
      <span class="font-semibold text-lg">Table of contents</span>
      <div class="pl-4 py-4 grid justify-start">
        <a
          v-if="qcMode"
          href="#quality-control"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Quality Control</a
        >
        <a
          href="#summary"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Project Summary</a
        >
        <a
          href="#photos-distribution"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Photos Distribution</a
        >
        <a
          href="#photos-per-sector-and-orbit"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Photos per Sector and Orbit</a
        >
        <a
          href="#average-speed"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Average Speed</a
        >
        <a
          v-if="displayEstimatedTowerHeight()"
          href="#estimated-tower-height"
          class="underline cursor-pointer text-gray-700 hover:text-gray-500"
          >Estimated tower height</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["qcMode", "reportData"],
  methods: {
    displayEstimatedTowerHeight() {
      if (!this.reportData) return;
      const { tower_data } = this.reportData;
      if (!tower_data) return;
      const { informed_tower_height, estimated_tower_height } = tower_data;
      if (!informed_tower_height || !estimated_tower_height) return;
      if (informed_tower_height === 0 || !estimated_tower_height === 0) return;
      return true;
    },
  },
};
</script>
