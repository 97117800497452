<template>
  <div v-if="!!loadedData" :style="`height: ${containerHeight}px`">
    <Plotly :graphData="graphData" :graphTitle="title" />
  </div>
</template>

<script>
import Plotly from "./Plotly.vue";
import {
  getErrorPhotoChartData,
  getAffectedPhotosChartData,
  getAffectedOrbitsChartData,
} from "../utils";

export default {
  name: "ErrorChart",
  props: {
    photos: Array,
    highlightOrbit: Array,
    highlightPhotos: Array,
    title: String,
    containerHeight: Number,
  },
  components: {
    Plotly,
  },
  methods: {
    loadDefaultPhotosData() {
      if (!this.photos) return console.error("No photos array found!");
      const photosGraphData = getErrorPhotoChartData(this.photos);
      const photos_longitude = photosGraphData.photos_longitude;
      const photos_latitude = photosGraphData.photos_latitude;
      const photos_absolute_altitude = photosGraphData.photos_absolute_altitude;
      const colors = photosGraphData.colors;

      return {
        name: "Photos",
        type: this.graphType,
        mode: this.graphMode,
        x: photos_longitude,
        y: photos_latitude,
        z: photos_absolute_altitude,
        opacity: 0.7,
        marker: {
          size: 2.5,
          color: colors,
        },
        hovertemplate:
          `<span>lng</span>: %{x:.6f}` +
          `<br><span>lat</span>: %{y:.6f}<br>` +
          `<span>abs alt</span>: %{z:.6f}<br>`,
      };
    },
    loadAffectedOrbitsData() {
      if (!this.photos) return console.error("No photos array found!");
      const photosGraphData = getAffectedOrbitsChartData(
        this.photos,
        this.highlightOrbit
      );
      const photos_longitude = photosGraphData.photos_longitude;
      const photos_latitude = photosGraphData.photos_latitude;
      const photos_absolute_altitude = photosGraphData.photos_absolute_altitude;
      const colors = photosGraphData.colors;

      return {
        name: "Affected Orbits",
        type: this.graphType,
        mode: this.graphMode,
        x: photos_longitude,
        y: photos_latitude,
        z: photos_absolute_altitude,
        opacity: 0.7,
        marker: {
          size: 3.5,
          color: colors,
        },
        hovertemplate:
          `<span>lng</span>: %{x:.6f}` +
          `<br><span>lat</span>: %{y:.6f}<br>` +
          `<span>abs alt</span>: %{z:.6f}<br>`,
      };
    },
    loadAffectedPhotosData() {
      if (!this.photos) return console.error("No photos array found!");
      const photosGraphData = getAffectedPhotosChartData(
        this.photos,
        this.highlightPhotos
      );
      const photos_longitude = photosGraphData.photos_longitude;
      const photos_latitude = photosGraphData.photos_latitude;
      const photos_absolute_altitude = photosGraphData.photos_absolute_altitude;
      const colors = photosGraphData.colors;

      return {
        name: "Affected Photos",
        type: this.graphType,
        mode: this.graphMode,
        x: photos_longitude,
        y: photos_latitude,
        z: photos_absolute_altitude,
        opacity: 1,
        marker: {
          size: 3.5,
          color: colors,
        },
        hovertemplate:
          `<span>lng</span>: %{x:.6f}` +
          `<br><span>lat</span>: %{y:.6f}<br>` +
          `<span>abs alt</span>: %{z:.6f}<br>`,
      };
    },
    loadData() {
      const defaultPhotosChartData = this.loadDefaultPhotosData();
      const affectedOrbitsData = this.loadAffectedOrbitsData();
      const affectedPhotosData = this.loadAffectedPhotosData();
      return [defaultPhotosChartData, affectedOrbitsData, affectedPhotosData];
    },

    getGraphData() {
      this.graphData = this.loadData();
      this.loadedData = true;
    },
  },

  data() {
    return {
      photos_longitude: [],
      photos_latitude: [],
      photos_absolute_altitude: [],
      colors_indexes: [],
      graphType: "scatter3d",
      graphMode: "markers",
      loadedData: false,
    };
  },
  mounted() {
    this.getGraphData();
  },
  watch: {
    photos: function () {
      this.getGraphData();
    },
  },
};
</script>
