<template>
  <div class="border border-gray-200 rounded">
    <div
      :class="[
        `${
          isCollapsed ? 'bg-white' : 'bg-primary-500'
        }  p-2 cursor-pointer rounded flex content-center`,
      ]"
      @click="onHeaderClick"
    >
      <div
        :class="[
          `flex-1 font-medium ${isCollapsed ? 'text-gray-700' : 'text-white'}`,
        ]"
      >
        #{{ index + 1 }} - {{ data.title }}
      </div>
      <div
        v-if="isCollapsed"
        class="
          pt-1
          whitespace-nowrap
          text-xs
          font-medium
          text-gray-700
          justify-self-end
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="transform: rotateZ(90deg)"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </div>
      <div
        v-if="!isCollapsed"
        class="pt-1 whitespace-nowrap text-xs font-medium text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
    <div
      class="bg-white ease-in-out duration-300 overflow-hidden"
      :style="`max-height:${panelHeight}px;`"
    >
      <div class="p-6 panel">
        <div v-if="data.description" class="flex w-full mt-2">
          <span class="font-medium">Description:</span>
          <span class="flex-1 text-gray-700 font-normal pl-8">{{
            data.description
          }}</span>
        </div>
        <div v-if="showChart">
          <ErrorChart
            :title="data.title + index"
            :highlightOrbit="data.highlightOrbit"
            :highlightPhotos="data.highlightPhotos"
            :photos="photos"
            :containerHeight="containerHeight"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorChart from "./ErrorChart.vue";
export default {
  name: "ErrorAccordion",
  props: ["data", "photos", "index"],
  components: {
    ErrorChart,
  },

  data() {
    return {
      clusters: [],
      droneAvgSpeed: "",
      isCollapsed: true,
      showChart: false,
      panelHeight: 0,
      collpseTimer: null,
      containerHeight: 450,
    };
  },
  methods: {
    onHeaderClick(e) {
      const headerNode = e.target;
      if (!headerNode) return;
      const panel = document.querySelector(".panel");

      if (this.collpseTimer) clearTimeout(this.collpseTimer);

      if (!this.showChart) {
        this.showChart = true;
        setTimeout(() => {
          this.panelHeight = this.isCollapsed
            ? panel.scrollHeight + this.containerHeight
            : 0;
          this.isCollapsed = !this.isCollapsed;
        }, 200);
      } else {
        this.panelHeight = this.isCollapsed
          ? panel.scrollHeight + this.containerHeight
          : 0;
        this.isCollapsed = !this.isCollapsed;
        setTimeout(() => {
          this.showChart = true;
        }, 200);
      }
    },
  },
  mounted() {},
};
</script>